import axios from "../axios.instance";

const CancelToken = axios.CancelToken;
let source = '';

export default class EmployeeEmergContactService {
    async getEmployeeEmergContact(id) {        
        try {
            source = CancelToken.source();
            const res = await axios.get('/emp-emerg-contact/'+id, {
                cancelToken: source.token
            })
            return res.data
            // if(res.data.length > 0){
            //     return res.data
            // } else {
            //     return []
            // }
        } catch (error) {
            // console.log(error.message)
            return error.response.data
        }
    }
    
    cancelRequest() {
        source.cancel('Request Canceled');
    }

    async addEmployeeContact(data) {
        try {
            if(data != ''){
                const res = await axios.post('/emp-emerg-contact', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateEmployeeContact(contact_id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/emp-emerg-contact/'+contact_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteEmployeeContact(contact_id) {
        try {
            if(contact_id != 0) {
                const res = await axios.delete('/emp-emerg-contact/'+contact_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}