<template>
<transition name="show">
    <div v-if="isContactListShow" class="sub-datatable">
        <DataTable
            :value="empEmergContactList"
            responsiveLayout="scroll"
            scrollHeight="200px"
            v-model:selection="selectedEmp_EmergContact" 
            selectionMode="single"
            dataKey="id"
            :loading="loading"
        >
            <Column field="title" header="Title" :style="{'width':'150px', 'min-width':'90px'}" />
            <Column field="first_name" header="First Name" :style="{'width':'350px', 'min-width':'130px'}"></Column>
            <Column field="last_name" header="Last Name" :style="{'width':'350px', 'min-width':'130px'}"></Column>
            <Column field="relation" header="Relation" :style="{'width':'260px', 'min-width':'100px'}"></Column>
            <Column field="contact_num" header="Contact Number" :style="{'width':'260px', 'min-width':'130px'}"></Column>
            
            <Column v-if="can('update', 'employee') || can('delete', 'employee')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button v-if="can('update', 'employee')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="showUpdateContactForm(slotProps.index)"/>
                    <Button v-if="can('delete', 'employee')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="deleteEmployeeContact(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</transition>

<transition name="show">        
    <div v-if="!isContactListShow" class="emp-contact-form">
        <form @submit.prevent="form_type == 'add' ? addEmployeeContact() : updateEmployeeContact()">
            <div class="p-fluid">
                <div class="p-field p-grid">
                    <label for="title" class="p-col-fixed">Title*:</label>
                    <div class="p-col" >
                        <Dropdown style="width:300px" v-model="selectedTitle" @change="onTitleChange()" :options="person_title" optionLabel="title" placeholder="Select title" :class="{'p-invalid':v$.title.$error}" />
                        <small v-if="v$.title.$error" class="p-error">{{v$.title.required.$message.replace('Value', 'Title')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="first_name" class="p-col-fixed" >First name*:</label>
                    <div class="p-col">
                        <InputText id="first_name" v-model="empContactForm.first_name" type="text" :class="{'p-invalid':v$.first_name.$error}"/>
                        <small v-if="v$.first_name.$error" class="p-error">{{v$.first_name.required.$message.replace('Value', 'First name')}}</small>
                    </div>
                    
                </div>
                <div class="p-field p-grid">
                    <label for="last_name" class="p-col-fixed">Last name*:</label>
                    <div class="p-col">
                        <InputText id="last_name" v-model="empContactForm.last_name" type="text" :class="{'p-invalid':v$.last_name.$error}"/>
                        <small v-if="v$.last_name.$error" class="p-error">{{v$.last_name.required.$message.replace('Value', 'Last name')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="relation" class="p-col-fixed">Relation*:</label>
                    <div class="p-col">
                        <InputText id="relation" v-model="empContactForm.relation" type="text" :class="{'p-invalid':v$.relation.$error}"/>
                        <small v-if="v$.relation.$error" class="p-error">{{v$.relation.required.$message.replace('Value', 'Relation')}}</small>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="phone" class="p-col-fixed">Contact number*:</label>
                    <div class="p-col">
                        <InputMask mask="999 999-999?9" v-model="empContactForm.contact_num" :class="{'p-invalid':v$.contact_num.$error}"/>
                        <small v-if="v$.contact_num.$error" class="p-error">{{v$.contact_num.required.$message.replace('Value', 'Contact number')}}</small>
                    </div>
                </div>
            </div>
            <div class="search-bar">
                <div v-if="form_type == 'add'">
                    <Button label="Save" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                </div>
                <div v-else>
                    <Button label="Update" type="submit" class="p-button-sm p-button-warning" icon="pi pi-save"></Button>
                </div>
                <Button label="Close" @click="displayContactList(true)" class="p-button-sm p-button-secondary" icon="pi pi-times" />
                <Message class="custom-msg" :life="messages_life" :sticky="false" v-for="msg of messages" :key="msg.content" :severity="msg.severity">{{msg.content}}</Message>
            </div>
        </form>
    </div>
</transition>
<hr style="border: 0; border-top: 1px solid var(--surface-d);"/>
<div v-if="isContactListShow" class="search-bar">
    <Button v-if="can('create', 'employee')" label="Add Contact" @click="displayContactList(false)" class="p-button-sm" />
    <Message class="custom-msg" :life="messages_life" :sticky="false" v-for="msg of messages" :key="msg.content" :severity="msg.severity">{{msg.content}}</Message>
</div>
</template>

<script>
import { ref, reactive, computed, onMounted, watchEffect } from 'vue'
import { FilterMatchMode } from 'primevue/api';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

import EmployeeEmergContactService from '../service/EmployeeEmergContactService';

export default {
    setup(){
        const { can } = useAbility()
        const store = useStore()
        const confirm = useConfirm()

        const messages = ref([]);
        let messages_life = ref(0)
        
        let loading = ref(false)
        let selectedEmp_EmergContact = ref()
        const isContactListShow = computed(() => store.state.employeeInfo.isContactList);
        const emp_id = computed(() => store.state.employeeInfo.EmpID);
        const person_title = [{title: 'Mr.'}, {title: 'Ms.'}, {title: 'Mrs.'}]
        let selectedTitle = ref('')

        let empEmergContactList = ref([])
        // let empEmergContactList = ref([
        //         { id: '1', title: 'Mr.', first_name: 'ABC', last_name: 'ABC', relation: 'M', contact_num: '0' },
        //         { id: '1', title: 'Mr.', first_name: 'ABC', last_name: 'ABC', relation: 'M', contact_num: '0' },
        //         { id: '1', title: 'Mr.', first_name: 'ABC', last_name: 'ABC', relation: 'M', contact_num: '0' },
        //         { id: '1', title: 'Mr.', first_name: 'ABC', last_name: 'ABC', relation: 'M', contact_num: '0' },
        //         { id: '1', title: 'Mr.', first_name: 'ABC', last_name: 'ABC', relation: 'M', contact_num: '0' },
        // ])
        
        let empContactForm = reactive({})
        let form_type = ref('')
        
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });

        //On Created
        const employeeEmergContactService = ref(new EmployeeEmergContactService())

        onMounted(() => {
            initContactForm()
        })

        const initContactForm = () =>{
            const initForm = {
                title: '',
                first_name: '',
                last_name: '',
                relation: '',
                contact_num: '',
                emp_id: ''
            }
            selectedTitle.value = ''

            Object.assign(empContactForm, initForm)
        }

        watchEffect((onInvalidate) => {
            const waitAPI = setTimeout(() => {
                setLoading(true)
            }, 800)
            employeeEmergContactService.value.getEmployeeEmergContact(emp_id.value).then((data) => {
                if(!data.errorResponse) {
                    empEmergContactList.value = data;
                    clearInterval(waitAPI)
                    setLoading(false)
                } else {
                    // if(empEmergContactList.value) {
                    //     empEmergContactList.value.length = 0;
                    // }
                    clearInterval(waitAPI)
                    setLoading(false)
                }
            });

            //Cancel previous request if emp_id changed
            onInvalidate(() => {
                employeeEmergContactService.value.cancelRequest()
                clearInterval(waitAPI)
            })
        })

        const addEmployeeContact = () => {
            if(validateForm()){
                empContactForm.emp_id = emp_id.value
                        
                employeeEmergContactService.value.addEmployeeContact(empContactForm).then((data) => {
                    if(data.errorResponse) {
                        showMessage('error', 'Error '+data.status+': '+data.errorResponse, 4000)
                    } else {
                        initContactForm();
                        showMessage('success', 'Data successfully added.', 2500)
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const updateEmployeeContact = () => {
            if(validateForm()){
                employeeEmergContactService.value.updateEmployeeContact(empContactForm.id, empContactForm).then((data) => {
                    if(data.errorResponse) {
                        showMessage('error', 'Update error '+data.status+': '+data.errorResponse, 4000)
                    } else {
                        showMessage('success', 'Data successfully updated.', 2500)
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const deleteEmployeeContact = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete '+ data.first_name + ' ' + data.last_name +'?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const contact_id = data.id
                    employeeEmergContactService.value.deleteEmployeeContact(contact_id).then((data) => {
                        if(!data.errorResponse){
                            empEmergContactList.value = empEmergContactList.value.filter(val => val.id !== contact_id);
                        } else {
                            showMessage('error', 'Delete error '+data.status+': '+data.errorResponse, 4000)
                        }
                    })
                }
            });
        }

        const showUpdateContactForm = (form_index) => {      
            displayContactList(false)
            form_type.value = 'update'
            selectedTitle.value = {title: empEmergContactList.value[form_index].title}
            Object.assign(empContactForm, empEmergContactList.value[form_index])
        }

        const showMessage = (severity, msgContent, life) => {
            messages.value = [{severity: severity, content: msgContent}]

            messages_life.value = life
            setTimeout(() => {
                messages.value.length = 0;
            }, life)
        }

        const onTitleChange = () => {
            empContactForm.title = selectedTitle.value.title
        }

        const setLoading = (status) => {
            loading.value = status
        }

        const displayContactList = (displayList) => {
            //When contact list display
            store.dispatch('setIsContactList', displayList)
            initContactForm()
            form_type.value = 'add'            
            messages.value.length = 0;
            v$.value.$reset() //Reset validations

            //Refresh data when contact form closed
            if(displayList === true) {
                setLoading(true)
                employeeEmergContactService.value.getEmployeeEmergContact(emp_id.value).then((data) => {
                    if(!data.errorResponse) {
                        empEmergContactList.value = data;
                        setLoading(false)
                    }
                });
            }
        }

        //Form Validations
        const rules = computed(() => {
            return {
                title: { required },
                first_name: { required },
                last_name: { required },
                relation: { required },
                contact_num: { required }
            }            
        })
        const v$ = useVuelidate(rules, empContactForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            can,
            filters,
            loading,
            person_title,
            selectedTitle,
            selectedEmp_EmergContact,
            empEmergContactList,
            isContactListShow,
            displayContactList,
            v$,
           
            messages,
            messages_life,

            form_type,
            empContactForm,
            addEmployeeContact,
            updateEmployeeContact,
            deleteEmployeeContact,
            showUpdateContactForm,
            onTitleChange,
        }
    }, 
    components: {
        InputText,
        Button,
        DataTable,
        Column,
    }
}
</script>