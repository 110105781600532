<template>
    <div class="employee-list">
        <div class="search-bar">
            <!-- <router-link :to="{path: '/employee/add-employee', query: {empid :  totalRecord } }" target="_blank"> 
                <Button label="Add" class="p-button-sm" icon="pi pi-plus-circle" />
            </router-link> -->
            <div class="p-input-icon-left search-field">
                <i class="pi pi-search" />
                <InputText
                    type="text"
                    v-model="filters['global'].value"
                    placeholder="Search by name"
                />
            </div>
            <router-link to="/employee/add-employee" target="_blank"> 
                <Button v-if="can('create', 'employee')" label="Add Employee" style="width: 110px" class="p-button-sm" />
            </router-link>
        </div>   
        
        <div class="content-section implementation">
            <div class="employee-datatable card">
                <DataTable
                    :value="employees"
                    responsiveLayout="scroll"
                    scrollHeight="430px"
                    v-model:selection="selectedEmployee" 
                    selectionMode="single"
                    @rowSelect="onRowSelect"
                    v-model:filters="filters"
                    :globalFilterFields="['first_name', 'last_name']"
                    dataKey="id"
                >
                    <Column field="id" header="Emp. ID" :style="{'min-width':'70px'}">
                        <template #body="{data}">
                            {{"EMP" + (data.id+'').padStart(3,'0')}}
                        </template>
                    </Column>
                    <Column field="first_name" header="First Name" :style="{'min-width':'120px'}"></Column>
                    <Column field="last_name" header="Last Name" :style="{'min-width':'120px'}"></Column>
                    <Column field="sex" header="Gender" :style="{'min-width':'50px', 'max-width':'70px'}"></Column>
                    <Column field="dob" header="DOB" :style="{'min-width':'110px'}">
                        <template #body="{data}">
                            {{data.dob != null ? dayjs(data.dob).format('DD-MMM-YYYY') : ''}}
                        </template>
                    </Column>
                    <Column field="job_title" header="Job Title" :style="{'min-width':'140px'}"></Column>
                    <Column field="phone" header="Phone" :style="{'min-width':'110px'}"></Column>
                    <Column field="email" header="Email"></Column>

                    <Column v-if="can('update', 'employee') || can('delete', 'employee')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                        <template #body="slotProps">
                            <router-link :to="'/employee/update-employee/'+slotProps.data.id" target="_blank"> 
                                <Button v-if="can('update', 'employee')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" />
                            </router-link>
                            <Button v-if="can('delete', 'employee')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteEmployee(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
                <ConfirmDialog></ConfirmDialog>
            </div> 
            
            <div class="tabmenu card">
                <TabView lazy class="tabview-custom-header" >
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-phone"></i>
                            <span>Emergency Contact</span>
                        </template>
                        <div v-if="displayComponent">
                            <EmployeeContact />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <i class="pi pi-calendar"></i>
                            <span>Permission Record</span>                            
                        </template>
                        <h5>Feature coming soon...</h5>                           
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from "primevue/useconfirm";
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import dayjs from 'dayjs';

import EmployeeService from '../service/EmployeeService';
import EmployeeContact from '../components/Employee_EmergencyContact.vue'
// const EmployeeContact = defineAsyncComponent(() => import('../components/Employee_EmergencyContact.vue'/* webpackChunkName: "employeecontact" */))

export default {
    setup(){        
        const { can } = useAbility();
        const confirm = useConfirm();
        const store = useStore();
        const toast = useToast()

        let selectedEmployee = ref()
        let employees = ref([])
        const displayComponent = ref(false)
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });

        const employeeContacts = ref(null)

        //On Created
        const employeeService = ref(new EmployeeService());

        //On Mounted
        onMounted(() => {
            getEmployeeData();
        })

        //Methods
        const onRowSelect = (event) => {
            store.dispatch('setEmpID', event.data.id)
            store.dispatch('setIsContactList', true)
            displayComponent.value = true
        }

        const getEmployeeData = () => {
            employeeService.value.getEmployees().then((data) => {
                if(!data.errorResponse) {
                    employees.value = data;
                } else {
                    toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            });
        }

        const confirmDeleteEmployee = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete '+ data.first_name + ' ' + data.last_name +'?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const emp_id = data.id
                    const photo = data.photo
                    employeeService.value.deleteEmployee(emp_id, photo).then((data) => {
                        if(!data.errorResponse){
                            employees.value = employees.value.filter(val => val.id !== emp_id);
                        } else {
                            // alert('Something went wrong while deleting employee.');
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        return {
            filters,            
            employees,
            selectedEmployee,
            displayComponent,

            //Methods
            onRowSelect,
            dayjs,
            confirmDeleteEmployee,

            employeeContacts,
            can,
        }
    },
    
    components: {
        EmployeeContact
    }
}
</script>

<style lang="css" scoped>
    .employee-datatable {
        height: 450px;
    }

    .tabmenu {
        height: auto;
        min-height: 21.8rem;
        padding: 0px 10px;
    }
</style>